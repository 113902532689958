import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ContactMain from '../components/contactMain'
import { MDBAnimation } from 'mdbreact'

const Contact = ({ data }) => {
  const heroImage = data.heroImage

  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65 logo-overlay"
            gradientClass="mask d-flex justify-content-center align-items-center gradient"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you."
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <ContactMain />
            </MDBAnimation>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default Contact 

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Contact Us"
      description="Want more information on UNICOM Capital products and services? Please get in touch using the contact details or web form provided."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`